import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import {
  Root,
  Header,
  Footer,
  Menu
} from 'fux'

import styles from './layout.css' // eslint-disable-line no-unused-vars
import { siteMap } from './../constants/sitemap'

export default function Layout ({ theme, children }) {
  const [ active, setActive ] = useState()
  useEffect(() => {
    const url = typeof window !== 'undefined' ? window.location.href : ''
    if (url.indexOf('/api/') !== -1) {
      setActive('/api/')
    } else if (url.indexOf('/ttninjs/') !== -1) {
      setActive('/ttninjs/')
    } else if (url.indexOf('/ttnewsml/') !== -1) {
      setActive('/ttnewsml/')
    } else if (url.indexOf('/metadata/') !== -1) {
      setActive('/metadata/')
    } else if (url.indexOf('/namespaces/') !== -1) {
      setActive('/namespaces/')
    } else if (url.indexOf('/tools/') !== -1) {
      setActive('/tools/')
    }
  }, [])

  return (
    <StaticQuery
      query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
        allMarkdownRemark(filter: {frontmatter: {navbar: {gt: 0}}}, sort: {fields: frontmatter___navbar}) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                title
              }
            }
          }
        }
      }
      `}
      render={(data) => (
        <Root>
          <Header
            siteMap={siteMap}
            transparent={theme}
            theme={theme}
            logoUrl='/'
            title='Developer Docs'
            noBurgerMenu
            right={
              <Menu theme={theme} data={data.allMarkdownRemark.edges.map(({ node }) => {
                return {
                  title: node.frontmatter.title,
                  active: node.fields.slug === active,
                  type: 'url',
                  href: node.fields.slug,
                  target: '_self'
                }
              })}
              />
            }
          />
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: data.site.siteMetadata.description },
              { name: 'keywords', content: 'gatsbyjs, gatsby, javascript, sample, something' }
            ]}
          />
          <div style={{ padding: 0, minHeight: '100vh' }}>{children}</div>
          <Footer />
        </Root>
      )}
    />
  )
}
