export const siteMap = {
    "metadata":{
      "tags":[]
    },
    "sys":{
      "space":{
        "sys":{
          "type":"Link",
          "linkType":"Space",
          "id":"qkrg69mv1uhf"
        }
      },
      "id":"5o2Gy0X3utKuNv0B8LNZq9",
      "type":"Entry",
      "createdAt":"2020-05-30T21:17:33.350Z",
      "updatedAt":"2020-11-26T08:13:12.224Z",
      "environment":{
        "sys":{
          "id":"master",
          "type":"Link",
          "linkType":"Environment"
        }
      },
      "revision":9,
      "contentType":{
        "sys":{
          "type":"Link",
          "linkType":"ContentType",
          "id":"sitemap"
        }
      },
      "locale":"sv"
    },
    "fields":{
      "slug":"main2",
      "title":"TT Better",
      "menu":[
        {
          "metadata":{
            "tags":[]
          },
          "sys":{
            "space":{
              "sys":{
                "type":"Link",
                "linkType":"Space",
                "id":"qkrg69mv1uhf"
              }
            },
            "id":"BqKLJonlsGrz4cWGR5JzM",
            "type":"Entry",
            "createdAt":"2020-02-13T13:41:52.308Z",
            "updatedAt":"2020-05-30T19:23:39.397Z",
            "environment":{
              "sys":{
                "id":"master",
                "type":"Link",
                "linkType":"Environment"
              }
            },
            "revision":2,
            "contentType":{
              "sys":{
                "type":"Link",
                "linkType":"ContentType",
                "id":"menu"
              }
            },
            "locale":"sv"
          },
          "fields":{
            "title":"Nyheter",
            "link":[
              {
                "metadata":{
                  "tags":[]
                },
                "sys":{
                  "space":{
                    "sys":{
                      "type":"Link",
                      "linkType":"Space",
                      "id":"qkrg69mv1uhf"
                    }
                  },
                  "id":"12XwTqGFFuvOQ7MQkmMnC6",
                  "type":"Entry",
                  "createdAt":"2020-02-13T13:37:50.822Z",
                  "updatedAt":"2021-02-03T17:04:17.131Z",
                  "environment":{
                    "sys":{
                      "id":"master",
                      "type":"Link",
                      "linkType":"Environment"
                    }
                  },
                  "revision":7,
                  "contentType":{
                    "sys":{
                      "type":"Link",
                      "linkType":"ContentType",
                      "id":"link"
                    }
                  },
                  "locale":"sv"
                },
                "fields":{
                  "text":"Nyheter",
                  "url":"https://tt.se/om/nyheter",
                  "image":{
                    "metadata":{
                      "tags":[]
                    },
                    "sys":{
                      "space":{
                        "sys":{
                          "type":"Link",
                          "linkType":"Space",
                          "id":"qkrg69mv1uhf"
                        }
                      },
                      "id":"49Ws0JQHnWzz4Lg0N0RRd1",
                      "type":"Asset",
                      "createdAt":"2020-03-22T21:11:51.262Z",
                      "updatedAt":"2020-06-02T19:06:43.583Z",
                      "environment":{
                        "sys":{
                          "id":"master",
                          "type":"Link",
                          "linkType":"Environment"
                        }
                      },
                      "revision":2,
                      "locale":"sv"
                    },
                    "fields":{
                      "title":"nyheter-noti200x200",
                      "file":{
                        "url":"//images.ctfassets.net/qkrg69mv1uhf/49Ws0JQHnWzz4Lg0N0RRd1/18b19337304094297ca3a70554ca33ea/news-ikon-img.jpg",
                        "details":{
                          "size":30557,
                          "image":{
                            "width":400,
                            "height":400
                          }
                        },
                        "fileName":"news-ikon-img.jpg",
                        "contentType":"image/jpeg"
                      }
                    }
                  },
                  "subheader":"Nyheter från TT",
                  "linktype":"internal"
                }
              }
            ],
            "menu":[
              {
                "metadata":{
                  "tags":[]
                },
                "sys":{
                  "space":{
                    "sys":{
                      "type":"Link",
                      "linkType":"Space",
                      "id":"qkrg69mv1uhf"
                    }
                  },
                  "id":"79s01TXJ7o7n6bfW4nPnbV",
                  "type":"Entry",
                  "createdAt":"2020-05-30T19:23:35.411Z",
                  "updatedAt":"2020-11-26T08:13:02.414Z",
                  "environment":{
                    "sys":{
                      "id":"master",
                      "type":"Link",
                      "linkType":"Environment"
                    }
                  },
                  "revision":6,
                  "contentType":{
                    "sys":{
                      "type":"Link",
                      "linkType":"ContentType",
                      "id":"menu"
                    }
                  },
                  "locale":"sv"
                },
                "fields":{
                  "title":"Nyheter sub",
                  "link":[
                    {
                      "metadata":{
                        "tags":[]
                      },
                      "sys":{
                        "space":{
                          "sys":{
                            "type":"Link",
                            "linkType":"Space",
                            "id":"qkrg69mv1uhf"
                          }
                        },
                        "id":"2aCsE78UkrLt4ibBpiQDB5",
                        "type":"Entry",
                        "createdAt":"2020-06-02T18:17:39.454Z",
                        "updatedAt":"2020-06-25T08:39:46.030Z",
                        "environment":{
                          "sys":{
                            "id":"master",
                            "type":"Link",
                            "linkType":"Environment"
                          }
                        },
                        "revision":4,
                        "contentType":{
                          "sys":{
                            "type":"Link",
                            "linkType":"ContentType",
                            "id":"link"
                          }
                        },
                        "locale":"sv"
                      },
                      "fields":{
                        "text":"Nyhetstjänsten",
                        "url":"/om/nyheter/nyhetstjansten",
                        "subheader":"Sub nyheter"
                      }
                    },
                    {
                      "metadata":{
                        "tags":[]
                      },
                      "sys":{
                        "space":{
                          "sys":{
                            "type":"Link",
                            "linkType":"Space",
                            "id":"qkrg69mv1uhf"
                          }
                        },
                        "id":"5tmdl3hJsofEpNXpqIh9GA",
                        "type":"Entry",
                        "createdAt":"2020-05-30T19:23:31.106Z",
                        "updatedAt":"2020-05-30T19:23:31.106Z",
                        "environment":{
                          "sys":{
                            "id":"master",
                            "type":"Link",
                            "linkType":"Environment"
                          }
                        },
                        "revision":1,
                        "contentType":{
                          "sys":{
                            "type":"Link",
                            "linkType":"ContentType",
                            "id":"link"
                          }
                        },
                        "locale":"sv"
                      },
                      "fields":{
                        "text":"Bevakning",
                        "url":"/om/nyheter/bevakning"
                      }
                    },
                    {
                      "metadata":{
                        "tags":[]
                      },
                      "sys":{
                        "space":{
                          "sys":{
                            "type":"Link",
                            "linkType":"Space",
                            "id":"qkrg69mv1uhf"
                          }
                        },
                        "id":"6XDjNTkZV1gBttREZXPBNN",
                        "type":"Entry",
                        "createdAt":"2020-11-26T08:10:56.672Z",
                        "updatedAt":"2020-11-26T08:14:41.073Z",
                        "environment":{
                          "sys":{
                            "id":"master",
                            "type":"Link",
                            "linkType":"Environment"
                          }
                        },
                        "revision":2,
                        "contentType":{
                          "sys":{
                            "type":"Link",
                            "linkType":"ContentType",
                            "id":"link"
                          }
                        },
                        "locale":"sv"
                      },
                      "fields":{
                        "text":"Kalender",
                        "url":"/om/nyheter/kalender"
                      }
                    },
                    {
                      "metadata":{
                        "tags":[]
                      },
                      "sys":{
                        "space":{
                          "sys":{
                            "type":"Link",
                            "linkType":"Space",
                            "id":"qkrg69mv1uhf"
                          }
                        },
                        "id":"3pnrhEclUw1b7oXNLE3Aj6",
                        "type":"Entry",
                        "createdAt":"2020-06-03T06:06:25.165Z",
                        "updatedAt":"2020-06-03T06:11:04.893Z",
                        "environment":{
                          "sys":{
                            "id":"master",
                            "type":"Link",
                            "linkType":"Environment"
                          }
                        },
                        "revision":2,
                        "contentType":{
                          "sys":{
                            "type":"Link",
                            "linkType":"ContentType",
                            "id":"link"
                          }
                        },
                        "locale":"sv"
                      },
                      "fields":{
                        "text":"Live",
                        "url":"/om/nyheter/live"
                      }
                    }
                  ]
                }
              }
            ]
          }
        },
        {
          "metadata":{
            "tags":[]
          },
          "sys":{
            "space":{
              "sys":{
                "type":"Link",
                "linkType":"Space",
                "id":"qkrg69mv1uhf"
              }
            },
            "id":"3CqvYy6ZuxX7nsVxKwHwsj",
            "type":"Entry",
            "createdAt":"2020-02-13T13:43:15.329Z",
            "updatedAt":"2020-05-29T15:12:32.806Z",
            "environment":{
              "sys":{
                "id":"master",
                "type":"Link",
                "linkType":"Environment"
              }
            },
            "revision":2,
            "contentType":{
              "sys":{
                "type":"Link",
                "linkType":"ContentType",
                "id":"menu"
              }
            },
            "locale":"sv"
          },
          "fields":{
            "title":"Bild",
            "link":[
              {
                "metadata":{
                  "tags":[]
                },
                "sys":{
                  "space":{
                    "sys":{
                      "type":"Link",
                      "linkType":"Space",
                      "id":"qkrg69mv1uhf"
                    }
                  },
                  "id":"7uUzYSmpu87qTbTVlism84",
                  "type":"Entry",
                  "createdAt":"2020-02-13T13:42:48.046Z",
                  "updatedAt":"2021-02-03T17:04:51.711Z",
                  "environment":{
                    "sys":{
                      "id":"master",
                      "type":"Link",
                      "linkType":"Environment"
                    }
                  },
                  "revision":11,
                  "contentType":{
                    "sys":{
                      "type":"Link",
                      "linkType":"ContentType",
                      "id":"link"
                    }
                  },
                  "locale":"sv"
                },
                "fields":{
                  "text":"Bild",
                  "url":"https://tt.se/om/bild",
                  "image":{
                    "metadata":{
                      "tags":[]
                    },
                    "sys":{
                      "space":{
                        "sys":{
                          "type":"Link",
                          "linkType":"Space",
                          "id":"qkrg69mv1uhf"
                        }
                      },
                      "id":"6R5iKV1ZMwvBgk6xAZUbiR",
                      "type":"Asset",
                      "createdAt":"2020-03-22T21:12:36.076Z",
                      "updatedAt":"2020-06-02T19:16:50.347Z",
                      "environment":{
                        "sys":{
                          "id":"master",
                          "type":"Link",
                          "linkType":"Environment"
                        }
                      },
                      "revision":2,
                      "locale":"sv"
                    },
                    "fields":{
                      "title":"bild-noti200x200",
                      "file":{
                        "url":"//images.ctfassets.net/qkrg69mv1uhf/6R5iKV1ZMwvBgk6xAZUbiR/59c3de9613c278a658e6d3db7d1fefaf/bild-1-ikon.jpg",
                        "details":{
                          "size":30367,
                          "image":{
                            "width":398,
                            "height":398
                          }
                        },
                        "fileName":"bild-1-ikon.jpg",
                        "contentType":"image/jpeg"
                      }
                    }
                  },
                  "subheader":"Foto, video och grafik",
                  "linktype":"internal"
                }
              }
            ],
            "menu":[
              {
                "metadata":{
                  "tags":[]
                },
                "sys":{
                  "space":{
                    "sys":{
                      "type":"Link",
                      "linkType":"Space",
                      "id":"qkrg69mv1uhf"
                    }
                  },
                  "id":"3LxX2RZwpGzDTtKCH8id8J",
                  "type":"Entry",
                  "createdAt":"2020-05-29T15:12:26.965Z",
                  "updatedAt":"2021-02-01T09:23:52.926Z",
                  "environment":{
                    "sys":{
                      "id":"master",
                      "type":"Link",
                      "linkType":"Environment"
                    }
                  },
                  "revision":12,
                  "contentType":{
                    "sys":{
                      "type":"Link",
                      "linkType":"ContentType",
                      "id":"menu"
                    }
                  },
                  "locale":"sv"
                },
                "fields":{
                  "title":"BildSub",
                  "link":[
                    {
                      "metadata":{
                        "tags":[]
                      },
                      "sys":{
                        "space":{
                          "sys":{
                            "type":"Link",
                            "linkType":"Space",
                            "id":"qkrg69mv1uhf"
                          }
                        },
                        "id":"1NsjEdI6HvUzbqCwNYV9ED",
                        "type":"Entry",
                        "createdAt":"2020-05-29T15:12:20.230Z",
                        "updatedAt":"2020-07-08T15:54:47.305Z",
                        "environment":{
                          "sys":{
                            "id":"master",
                            "type":"Link",
                            "linkType":"Environment"
                          }
                        },
                        "revision":5,
                        "contentType":{
                          "sys":{
                            "type":"Link",
                            "linkType":"ContentType",
                            "id":"link"
                          }
                        },
                        "locale":"sv"
                      },
                      "fields":{
                        "text":"Kom i gång",
                        "url":"/om/bild/kom-i-gang"
                      }
                    },
                    {
                      "metadata":{
                        "tags":[]
                      },
                      "sys":{
                        "space":{
                          "sys":{
                            "type":"Link",
                            "linkType":"Space",
                            "id":"qkrg69mv1uhf"
                          }
                        },
                        "id":"2yiySQmrjYITLmqEM85Kto",
                        "type":"Entry",
                        "createdAt":"2020-06-02T13:12:40.710Z",
                        "updatedAt":"2020-07-08T15:50:35.752Z",
                        "environment":{
                          "sys":{
                            "id":"master",
                            "type":"Link",
                            "linkType":"Environment"
                          }
                        },
                        "revision":6,
                        "contentType":{
                          "sys":{
                            "type":"Link",
                            "linkType":"ContentType",
                            "id":"link"
                          }
                        },
                        "locale":"sv"
                      },
                      "fields":{
                        "text":"Priser och paket",
                        "url":"/om/bild/priser-och-paket"
                      }
                    },
                    {
                      "metadata":{
                        "tags":[]
                      },
                      "sys":{
                        "space":{
                          "sys":{
                            "type":"Link",
                            "linkType":"Space",
                            "id":"qkrg69mv1uhf"
                          }
                        },
                        "id":"4bCgTAhF3yFC2ZK83HcaiS",
                        "type":"Entry",
                        "createdAt":"2021-02-01T09:22:15.501Z",
                        "updatedAt":"2021-02-01T09:24:22.597Z",
                        "environment":{
                          "sys":{
                            "id":"master",
                            "type":"Link",
                            "linkType":"Environment"
                          }
                        },
                        "revision":3,
                        "contentType":{
                          "sys":{
                            "type":"Link",
                            "linkType":"ContentType",
                            "id":"link"
                          }
                        },
                        "locale":"sv"
                      },
                      "fields":{
                        "text":"Mediebank",
                        "url":"/om/bild/mediebank"
                      }
                    }
                  ]
                }
              }
            ]
          }
        },
        {
          "metadata":{
            "tags":[]
          },
          "sys":{
            "space":{
              "sys":{
                "type":"Link",
                "linkType":"Space",
                "id":"qkrg69mv1uhf"
              }
            },
            "id":"7H3HHvdsGuulc1hs1wrVQi",
            "type":"Entry",
            "createdAt":"2020-02-13T13:44:06.278Z",
            "updatedAt":"2020-02-13T13:44:06.278Z",
            "environment":{
              "sys":{
                "id":"master",
                "type":"Link",
                "linkType":"Environment"
              }
            },
            "revision":1,
            "contentType":{
              "sys":{
                "type":"Link",
                "linkType":"ContentType",
                "id":"menu"
              }
            },
            "locale":"sv"
          },
          "fields":{
            "title":"Via TT",
            "link":[
              {
                "metadata":{
                  "tags":[]
                },
                "sys":{
                  "space":{
                    "sys":{
                      "type":"Link",
                      "linkType":"Space",
                      "id":"qkrg69mv1uhf"
                    }
                  },
                  "id":"1hBkzkw3bjMa4SuQ1KtyXI",
                  "type":"Entry",
                  "createdAt":"2020-02-13T13:43:54.077Z",
                  "updatedAt":"2020-08-25T11:13:25.760Z",
                  "environment":{
                    "sys":{
                      "id":"master",
                      "type":"Link",
                      "linkType":"Environment"
                    }
                  },
                  "revision":9,
                  "contentType":{
                    "sys":{
                      "type":"Link",
                      "linkType":"ContentType",
                      "id":"link"
                    }
                  },
                  "locale":"sv"
                },
                "fields":{
                  "text":"Via TT",
                  "url":"https://tt.se/via-tt",
                  "image":{
                    "metadata":{
                      "tags":[]
                    },
                    "sys":{
                      "space":{
                        "sys":{
                          "type":"Link",
                          "linkType":"Space",
                          "id":"qkrg69mv1uhf"
                        }
                      },
                      "id":"2OeyqIJTFfKeQ1haYTP6oE",
                      "type":"Asset",
                      "createdAt":"2020-03-04T15:17:56.691Z",
                      "updatedAt":"2020-03-22T21:14:11.623Z",
                      "environment":{
                        "sys":{
                          "id":"master",
                          "type":"Link",
                          "linkType":"Environment"
                        }
                      },
                      "revision":2,
                      "locale":"sv"
                    },
                    "fields":{
                      "title":"lunch niceday howyougonnacomeonmtvwithhalfamoustache prematurebalding",
                      "file":{
                        "url":"//images.ctfassets.net/qkrg69mv1uhf/2OeyqIJTFfKeQ1haYTP6oE/4c9dfde4df0cd61f77399fee0db4a096/via-tt-noti200x200.jpg",
                        "details":{
                          "size":5965,
                          "image":{
                            "width":200,
                            "height":200
                          }
                        },
                        "fileName":"via-tt-noti200x200.jpg",
                        "contentType":"image/jpeg"
                      }
                    }
                  },
                  "subheader":"Ett enkelt pressverktyg",
                  "linktype":"internal"
                }
              }
            ]
          }
        }
      ],
      "link":[
        {
          "metadata":{
            "tags":[]
          },
          "sys":{
            "space":{
              "sys":{
                "type":"Link",
                "linkType":"Space",
                "id":"qkrg69mv1uhf"
              }
            },
            "id":"880Wtkpa7KhSfkjq0pWOu",
            "type":"Entry",
            "createdAt":"2020-02-13T13:35:40.399Z",
            "updatedAt":"2021-02-03T17:05:04.625Z",
            "environment":{
              "sys":{
                "id":"master",
                "type":"Link",
                "linkType":"Environment"
              }
            },
            "revision":9,
            "contentType":{
              "sys":{
                "type":"Link",
                "linkType":"ContentType",
                "id":"link"
              }
            },
            "locale":"sv"
          },
          "fields":{
            "text":"Startsidan",
            "url":"https://tt.se",
            "linktype":"internal"
          }
        },
        {
          "metadata":{
            "tags":[]
          },
          "sys":{
            "space":{
              "sys":{
                "type":"Link",
                "linkType":"Space",
                "id":"qkrg69mv1uhf"
              }
            },
            "id":"5m0zwzNFHO1KDQJXTIFzdp",
            "type":"Entry",
            "createdAt":"2020-02-13T13:47:18.411Z",
            "updatedAt":"2021-02-03T17:06:01.471Z",
            "environment":{
              "sys":{
                "id":"master",
                "type":"Link",
                "linkType":"Environment"
              }
            },
            "revision":6,
            "contentType":{
              "sys":{
                "type":"Link",
                "linkType":"ContentType",
                "id":"link"
              }
            },
            "locale":"sv"
          },
          "fields":{
            "text":"Om TT",
            "url":"https://tt.se/om/om-tt",
            "linktype":"internal"
          }
        },
        {
          "metadata":{
            "tags":[]
          },
          "sys":{
            "space":{
              "sys":{
                "type":"Link",
                "linkType":"Space",
                "id":"qkrg69mv1uhf"
              }
            },
            "id":"7dNwbGKXOq7pEkO7nGt3RI",
            "type":"Entry",
            "createdAt":"2020-02-13T13:48:20.539Z",
            "updatedAt":"2021-02-03T17:06:15.601Z",
            "environment":{
              "sys":{
                "id":"master",
                "type":"Link",
                "linkType":"Environment"
              }
            },
            "revision":12,
            "contentType":{
              "sys":{
                "type":"Link",
                "linkType":"ContentType",
                "id":"link"
              }
            },
            "locale":"sv"
          },
          "fields":{
            "text":"Produkter",
            "url":"https://tt.se/om/produkter",
            "linktype":"internal"
          }
        },
        {
          "metadata":{
            "tags":[]
          },
          "sys":{
            "space":{
              "sys":{
                "type":"Link",
                "linkType":"Space",
                "id":"qkrg69mv1uhf"
              }
            },
            "id":"7lg6TY1ESGx8hQXHgcJEZK",
            "type":"Entry",
            "createdAt":"2020-02-13T13:49:12.133Z",
            "updatedAt":"2020-11-11T08:58:38.291Z",
            "environment":{
              "sys":{
                "id":"master",
                "type":"Link",
                "linkType":"Environment"
              }
            },
            "revision":2,
            "contentType":{
              "sys":{
                "type":"Link",
                "linkType":"ContentType",
                "id":"link"
              }
            },
            "locale":"sv"
          },
          "fields":{
            "text":"Karriär",
            "url":"https://tt.se/om-oss/lediga-jobb/",
            "linktype":"external"
          }
        },
        {
          "metadata":{
            "tags":[]
          },
          "sys":{
            "space":{
              "sys":{
                "type":"Link",
                "linkType":"Space",
                "id":"qkrg69mv1uhf"
              }
            },
            "id":"2uSK54RSW1mJ6sVRKezpIV",
            "type":"Entry",
            "createdAt":"2020-02-13T13:49:37.630Z",
            "updatedAt":"2020-08-28T11:47:52.778Z",
            "environment":{
              "sys":{
                "id":"master",
                "type":"Link",
                "linkType":"Environment"
              }
            },
            "revision":2,
            "contentType":{
              "sys":{
                "type":"Link",
                "linkType":"ContentType",
                "id":"link"
              }
            },
            "locale":"sv"
          },
          "fields":{
            "text":"Kundsupport",
            "url":"https://support.tt.se/",
            "linktype":"external"
          }
        },
        {
          "metadata":{
            "tags":[]
          },
          "sys":{
            "space":{
              "sys":{
                "type":"Link",
                "linkType":"Space",
                "id":"qkrg69mv1uhf"
              }
            },
            "id":"4Imeg81eTpzv7VqJHSDRww",
            "type":"Entry",
            "createdAt":"2020-02-13T13:50:03.060Z",
            "updatedAt":"2020-02-13T13:50:03.060Z",
            "environment":{
              "sys":{
                "id":"master",
                "type":"Link",
                "linkType":"Environment"
              }
            },
            "revision":1,
            "contentType":{
              "sys":{
                "type":"Link",
                "linkType":"ContentType",
                "id":"link"
              }
            },
            "locale":"sv"
          },
          "fields":{
            "text":"Kontakt",
            "url":"https://tt.se/kontakt/",
            "linktype":"internal"
          }
        }
      ],
      "footer":{
        "metadata":{
          "tags":[]
        },
        "sys":{
          "space":{
            "sys":{
              "type":"Link",
              "linkType":"Space",
              "id":"qkrg69mv1uhf"
            }
          },
          "id":"8qPDDadi9EbLvBAUoQcRG",
          "type":"Entry",
          "createdAt":"2020-02-13T10:06:07.751Z",
          "updatedAt":"2020-05-09T19:19:44.507Z",
          "environment":{
            "sys":{
              "id":"master",
              "type":"Link",
              "linkType":"Environment"
            }
          },
          "revision":3,
          "contentType":{
            "sys":{
              "type":"Link",
              "linkType":"ContentType",
              "id":"footer"
            }
          },
          "locale":"sv"
        },
        "fields":{
          "title":"Wrapper footer",
          "footer":[
            {
              "metadata":{
                "tags":[]
              },
              "sys":{
                "space":{
                  "sys":{
                    "type":"Link",
                    "linkType":"Space",
                    "id":"qkrg69mv1uhf"
                  }
                },
                "id":"4BGauFE8tQQKbfsk5H0ndD",
                "type":"Entry",
                "createdAt":"2020-02-13T10:05:06.545Z",
                "updatedAt":"2020-05-28T13:42:22.226Z",
                "environment":{
                  "sys":{
                    "id":"master",
                    "type":"Link",
                    "linkType":"Environment"
                  }
                },
                "revision":6,
                "contentType":{
                  "sys":{
                    "type":"Link",
                    "linkType":"ContentType",
                    "id":"footer"
                  }
                },
                "locale":"sv"
              },
              "fields":{
                "title":"Upper footer",
                "menu":[
                  {
                    "metadata":{
                      "tags":[]
                    },
                    "sys":{
                      "space":{
                        "sys":{
                          "type":"Link",
                          "linkType":"Space",
                          "id":"qkrg69mv1uhf"
                        }
                      },
                      "id":"omZkAt5xpg6MmkBHTrOyw",
                      "type":"Entry",
                      "createdAt":"2020-02-13T09:57:47.401Z",
                      "updatedAt":"2020-06-02T17:08:09.789Z",
                      "environment":{
                        "sys":{
                          "id":"master",
                          "type":"Link",
                          "linkType":"Environment"
                        }
                      },
                      "revision":6,
                      "contentType":{
                        "sys":{
                          "type":"Link",
                          "linkType":"ContentType",
                          "id":"menu"
                        }
                      },
                      "locale":"sv"
                    },
                    "fields":{
                      "title":"Produkter",
                      "header":[
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"6CAP5fKRUmri3Hqf0jszuw",
                            "type":"Entry",
                            "createdAt":"2020-02-13T09:53:27.132Z",
                            "updatedAt":"2020-06-02T17:10:01.003Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":2,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"header"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "header":"Produkter"
                          }
                        }
                      ],
                      "link":[
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"3bjcbB0LZ5JeTAnQt7dDfE",
                            "type":"Entry",
                            "createdAt":"2020-02-13T09:54:36.669Z",
                            "updatedAt":"2020-05-30T15:52:43.265Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":2,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"Nyheter",
                            "url":"/om/nyheter"
                          }
                        },
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"3JHQPEHCgQC9rWg5WxCUv",
                            "type":"Entry",
                            "createdAt":"2020-02-13T09:54:57.604Z",
                            "updatedAt":"2020-02-13T09:54:57.604Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":1,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"Bildbank",
                            "url":"https://tt.se/bild/"
                          }
                        },
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"6yxBdsPgD4DBTM4AuKfRZE",
                            "type":"Entry",
                            "createdAt":"2020-02-13T09:55:15.294Z",
                            "updatedAt":"2020-02-13T09:55:15.294Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":1,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"Via TT",
                            "url":"https://tt.se/via-tt/"
                          }
                        },
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"2oYfsbhQ9Y3kYo1qGoEYJo",
                            "type":"Entry",
                            "createdAt":"2020-02-13T09:57:33.476Z",
                            "updatedAt":"2020-06-17T12:07:10.766Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":3,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"Fler produkter",
                            "url":"/om/produkter"
                          }
                        }
                      ]
                    }
                  },
                  {
                    "metadata":{
                      "tags":[]
                    },
                    "sys":{
                      "space":{
                        "sys":{
                          "type":"Link",
                          "linkType":"Space",
                          "id":"qkrg69mv1uhf"
                        }
                      },
                      "id":"6rBJKtbW4aHOdomRLxSOBZ",
                      "type":"Entry",
                      "createdAt":"2020-02-13T10:08:38.402Z",
                      "updatedAt":"2020-11-11T08:50:56.717Z",
                      "environment":{
                        "sys":{
                          "id":"master",
                          "type":"Link",
                          "linkType":"Environment"
                        }
                      },
                      "revision":3,
                      "contentType":{
                        "sys":{
                          "type":"Link",
                          "linkType":"ContentType",
                          "id":"menu"
                        }
                      },
                      "locale":"sv"
                    },
                    "fields":{
                      "title":"Beställ",
                      "header":[
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"3joBMqjRbXaKlLhLgXv2B6",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:06:34.668Z",
                            "updatedAt":"2020-02-13T10:06:34.668Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":1,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"header"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "header":"Beställ"
                          }
                        }
                      ],
                      "link":[
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"3kbg5UyLrNPFeICMLhXpFT",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:07:54.132Z",
                            "updatedAt":"2020-06-25T08:41:30.840Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":3,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"Nyhetstjänsten",
                            "url":"/om/nyheter/nyhetstjansten"
                          }
                        },
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"3davHSmQpnCoUWARusJLs4",
                            "type":"Entry",
                            "createdAt":"2020-11-11T08:50:20.587Z",
                            "updatedAt":"2020-11-26T14:45:42.154Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":4,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"TT-kalendern",
                            "url":"/om/nyheter/kalender/"
                          }
                        }
                      ]
                    }
                  },
                  {
                    "metadata":{
                      "tags":[]
                    },
                    "sys":{
                      "space":{
                        "sys":{
                          "type":"Link",
                          "linkType":"Space",
                          "id":"qkrg69mv1uhf"
                        }
                      },
                      "id":"7CfiEwMzjqOUcNiRHiKwS0",
                      "type":"Entry",
                      "createdAt":"2020-02-13T10:10:38.841Z",
                      "updatedAt":"2020-07-09T09:19:35.195Z",
                      "environment":{
                        "sys":{
                          "id":"master",
                          "type":"Link",
                          "linkType":"Environment"
                        }
                      },
                      "revision":4,
                      "contentType":{
                        "sys":{
                          "type":"Link",
                          "linkType":"ContentType",
                          "id":"menu"
                        }
                      },
                      "locale":"sv"
                    },
                    "fields":{
                      "title":"Företag",
                      "header":[
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"39Rx4F4lPSnqZxeMSHkLx8",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:08:54.647Z",
                            "updatedAt":"2020-02-13T10:08:54.647Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":1,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"header"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "header":"Företag"
                          }
                        }
                      ],
                      "link":[
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"1qxGAkhEEBbyqruuTrZi6i",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:09:14.864Z",
                            "updatedAt":"2020-11-11T13:34:50.234Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":4,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"Om TT",
                            "url":"/om/om-tt"
                          }
                        },
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"4we3xzvo83af3shcSlYPmL",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:09:40.282Z",
                            "updatedAt":"2020-02-13T10:09:40.282Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":1,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"Karriär",
                            "url":"https://tt.se/om-oss/lediga-jobb/"
                          }
                        },
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"5kYihAZMRu63pusy9RFEC1",
                            "type":"Entry",
                            "createdAt":"2020-07-09T08:28:53.483Z",
                            "updatedAt":"2020-07-09T08:47:23.992Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":2,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"Sociala medier",
                            "url":"/om/sociala-medier",
                            "linktype":"internal"
                          }
                        },
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"623QU6R9q1XbbkPfZHabno",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:10:08.766Z",
                            "updatedAt":"2020-02-13T10:10:08.766Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":1,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"Blogg",
                            "url":"https://tt.se/blogg/"
                          }
                        },
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"4LoYohsrtBk0702dC6soov",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:10:30.610Z",
                            "updatedAt":"2020-02-13T10:10:30.610Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":1,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"Press",
                            "url":"https://tt.se/press/"
                          }
                        },
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"1pGatd1s56uUHVAV58D6Yv",
                            "type":"Entry",
                            "createdAt":"2020-06-04T09:01:10.895Z",
                            "updatedAt":"2020-06-04T09:01:10.895Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":1,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"Utvecklare",
                            "url":"https://developer.tt.se"
                          }
                        }
                      ]
                    }
                  },
                  {
                    "metadata":{
                      "tags":[]
                    },
                    "sys":{
                      "space":{
                        "sys":{
                          "type":"Link",
                          "linkType":"Space",
                          "id":"qkrg69mv1uhf"
                        }
                      },
                      "id":"RFKGKk6bFeZs1Ap0hWnAh",
                      "type":"Entry",
                      "createdAt":"2020-02-13T10:13:30.245Z",
                      "updatedAt":"2020-11-30T09:37:14.861Z",
                      "environment":{
                        "sys":{
                          "id":"master",
                          "type":"Link",
                          "linkType":"Environment"
                        }
                      },
                      "revision":6,
                      "contentType":{
                        "sys":{
                          "type":"Link",
                          "linkType":"ContentType",
                          "id":"menu"
                        }
                      },
                      "locale":"sv"
                    },
                    "fields":{
                      "title":"Support",
                      "header":[
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"6x5RMDWYzQdavkEI2o6l4O",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:10:54.743Z",
                            "updatedAt":"2020-02-13T10:10:54.743Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":1,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"header"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "header":"Support"
                          }
                        }
                      ],
                      "link":[
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"1UvhTBqhAdpaBI7nzmHi7X",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:11:22.420Z",
                            "updatedAt":"2020-02-13T10:11:22.420Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":1,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"Kontakt",
                            "url":"https://tt.se/kontakt/"
                          }
                        },
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"56a7majAodifogG1kekBjt",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:11:58.479Z",
                            "updatedAt":"2020-08-24T14:27:02.681Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":3,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"Kundsupport",
                            "url":"https://support.tt.se/"
                          }
                        },
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"7p2q1oZ6bbTN6YhRvhzCiG",
                            "type":"Entry",
                            "createdAt":"2020-07-01T07:23:57.403Z",
                            "updatedAt":"2020-07-01T07:23:57.403Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":1,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"TT-språket",
                            "url":"https://tt.se/tt-spraket/",
                            "linktype":"external"
                          }
                        }
                      ]
                    }
                  },
                  {
                    "metadata":{
                      "tags":[]
                    },
                    "sys":{
                      "space":{
                        "sys":{
                          "type":"Link",
                          "linkType":"Space",
                          "id":"qkrg69mv1uhf"
                        }
                      },
                      "id":"1gpBVdnst24xEsoyEzkUlZ",
                      "type":"Entry",
                      "createdAt":"2020-02-13T10:15:34.904Z",
                      "updatedAt":"2020-04-21T13:39:01.712Z",
                      "environment":{
                        "sys":{
                          "id":"master",
                          "type":"Link",
                          "linkType":"Environment"
                        }
                      },
                      "revision":2,
                      "contentType":{
                        "sys":{
                          "type":"Link",
                          "linkType":"ContentType",
                          "id":"menu"
                        }
                      },
                      "locale":"sv"
                    },
                    "fields":{
                      "title":"Allmänna villkor",
                      "header":[
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"54gGE75elq2QCLN7Yth4vr",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:14:12.906Z",
                            "updatedAt":"2020-02-13T10:14:12.906Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":1,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"header"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "header":"Allmänna villkor"
                          }
                        }
                      ],
                      "link":[
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"6vkbIbaSdTL0bLfbG6O7v6",
                            "type":"Entry",
                            "createdAt":"2020-04-21T13:38:52.115Z",
                            "updatedAt":"2020-08-25T06:39:37.887Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":2,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"Cookies",
                            "url":"https://tt.se/gdpr/"
                          }
                        },
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"5kS4t6185m0WKoIefMrFcp",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:15:26.637Z",
                            "updatedAt":"2020-08-25T06:38:43.201Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":2,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"Sekretess och villkor",
                            "url":"https://tt.se/legal/"
                          }
                        }
                      ]
                    }
                  }
                ],
                "slug":"upper"
              }
            },
            {
              "metadata":{
                "tags":[]
              },
              "sys":{
                "space":{
                  "sys":{
                    "type":"Link",
                    "linkType":"Space",
                    "id":"qkrg69mv1uhf"
                  }
                },
                "id":"1zI9JCBh6S6D03lVRGqoNk",
                "type":"Entry",
                "createdAt":"2020-02-13T10:16:24.619Z",
                "updatedAt":"2020-05-09T19:20:00.590Z",
                "environment":{
                  "sys":{
                    "id":"master",
                    "type":"Link",
                    "linkType":"Environment"
                  }
                },
                "revision":3,
                "contentType":{
                  "sys":{
                    "type":"Link",
                    "linkType":"ContentType",
                    "id":"footer"
                  }
                },
                "locale":"sv"
              },
              "fields":{
                "title":"Bottom footer",
                "menu":[
                  {
                    "metadata":{
                      "tags":[]
                    },
                    "sys":{
                      "space":{
                        "sys":{
                          "type":"Link",
                          "linkType":"Space",
                          "id":"qkrg69mv1uhf"
                        }
                      },
                      "id":"1l9GCXIGrKePkMvbFedcJv",
                      "type":"Entry",
                      "createdAt":"2020-02-13T10:17:57.857Z",
                      "updatedAt":"2020-02-13T10:17:57.857Z",
                      "environment":{
                        "sys":{
                          "id":"master",
                          "type":"Link",
                          "linkType":"Environment"
                        }
                      },
                      "revision":1,
                      "contentType":{
                        "sys":{
                          "type":"Link",
                          "linkType":"ContentType",
                          "id":"menu"
                        }
                      },
                      "locale":"sv"
                    },
                    "fields":{
                      "title":"Växel",
                      "header":[
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"2sK7wZORNp6BhkfKHWmOXw",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:16:49.906Z",
                            "updatedAt":"2020-02-13T10:16:49.906Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":1,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"header"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "header":"Växel"
                          }
                        }
                      ],
                      "link":[
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"1S9JE057M31NyC0PMZYX58",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:17:50.425Z",
                            "updatedAt":"2020-02-13T10:17:50.425Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":1,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"08-692 26 00"
                          }
                        }
                      ]
                    }
                  },
                  {
                    "metadata":{
                      "tags":[]
                    },
                    "sys":{
                      "space":{
                        "sys":{
                          "type":"Link",
                          "linkType":"Space",
                          "id":"qkrg69mv1uhf"
                        }
                      },
                      "id":"6xHS4DETcSBYMuvgsZz4j",
                      "type":"Entry",
                      "createdAt":"2020-02-13T10:20:01.410Z",
                      "updatedAt":"2020-02-13T10:20:01.410Z",
                      "environment":{
                        "sys":{
                          "id":"master",
                          "type":"Link",
                          "linkType":"Environment"
                        }
                      },
                      "revision":1,
                      "contentType":{
                        "sys":{
                          "type":"Link",
                          "linkType":"ContentType",
                          "id":"menu"
                        }
                      },
                      "locale":"sv"
                    },
                    "fields":{
                      "title":"Försäljning och support",
                      "header":[
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"WoJP25LdXMhJdJJ9UQtBQ",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:18:15.310Z",
                            "updatedAt":"2020-02-13T10:18:15.310Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":1,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"header"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "header":"Försäljning och support"
                          }
                        }
                      ],
                      "link":[
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"1q4XOW9zCdSIdU3b0hoOPX",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:18:37.142Z",
                            "updatedAt":"2020-11-17T08:49:06.526Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":2,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"08-692 26 15"
                          }
                        },
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"5apomgcGGxGPn5NSbHPtw",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:19:38.770Z",
                            "updatedAt":"2020-02-13T10:19:38.770Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":1,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"Skicka e-post",
                            "url":"https://tt.se/kontakt/"
                          }
                        }
                      ]
                    }
                  },
                  {
                    "metadata":{
                      "tags":[]
                    },
                    "sys":{
                      "space":{
                        "sys":{
                          "type":"Link",
                          "linkType":"Space",
                          "id":"qkrg69mv1uhf"
                        }
                      },
                      "id":"6ylYTpmvgChcth9YyRDBjU",
                      "type":"Entry",
                      "createdAt":"2020-02-13T10:21:20.346Z",
                      "updatedAt":"2020-02-13T10:21:20.346Z",
                      "environment":{
                        "sys":{
                          "id":"master",
                          "type":"Link",
                          "linkType":"Environment"
                        }
                      },
                      "revision":1,
                      "contentType":{
                        "sys":{
                          "type":"Link",
                          "linkType":"ContentType",
                          "id":"menu"
                        }
                      },
                      "locale":"sv"
                    },
                    "fields":{
                      "title":"Tipsa redaktionen",
                      "header":[
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"32hezsLn5eVf490FPfl9Mm",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:20:38.360Z",
                            "updatedAt":"2020-02-13T10:20:38.360Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":1,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"header"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "header":"Tipsa redaktionen"
                          }
                        }
                      ],
                      "link":[
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"4MAkaIGirguHW45ZsBcbCF",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:21:05.853Z",
                            "updatedAt":"2020-02-13T10:21:05.853Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":1,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"Skicka e-post",
                            "url":"https://tt.se/om-oss/kontakt/tipsa-oss/"
                          }
                        }
                      ]
                    }
                  },
                  {
                    "metadata":{
                      "tags":[]
                    },
                    "sys":{
                      "space":{
                        "sys":{
                          "type":"Link",
                          "linkType":"Space",
                          "id":"qkrg69mv1uhf"
                        }
                      },
                      "id":"78X9J3evdrenNYxIuMosNt",
                      "type":"Entry",
                      "createdAt":"2020-02-13T10:22:22.003Z",
                      "updatedAt":"2020-08-25T06:56:19.155Z",
                      "environment":{
                        "sys":{
                          "id":"master",
                          "type":"Link",
                          "linkType":"Environment"
                        }
                      },
                      "revision":3,
                      "contentType":{
                        "sys":{
                          "type":"Link",
                          "linkType":"ContentType",
                          "id":"menu"
                        }
                      },
                      "locale":"sv"
                    },
                    "fields":{
                      "title":"In English",
                      "link":[
                        {
                          "metadata":{
                            "tags":[]
                          },
                          "sys":{
                            "space":{
                              "sys":{
                                "type":"Link",
                                "linkType":"Space",
                                "id":"qkrg69mv1uhf"
                              }
                            },
                            "id":"1GA5BBW3OkD9vqnPoK6P4p",
                            "type":"Entry",
                            "createdAt":"2020-02-13T10:22:17.241Z",
                            "updatedAt":"2020-02-13T10:22:17.241Z",
                            "environment":{
                              "sys":{
                                "id":"master",
                                "type":"Link",
                                "linkType":"Environment"
                              }
                            },
                            "revision":1,
                            "contentType":{
                              "sys":{
                                "type":"Link",
                                "linkType":"ContentType",
                                "id":"link"
                              }
                            },
                            "locale":"sv"
                          },
                          "fields":{
                            "text":"About us",
                            "url":"https://tt.se/en/about-us/"
                          }
                        }
                      ]
                    }
                  }
                ],
                "slug":"bottom"
              }
            }
          ],
          "slug":"wrapper"
        }
      }
    }
  }
